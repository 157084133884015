import React from 'react';

import { IIconProps } from './types';

const CalendarIconV2: React.FC<IIconProps> = ({
  className = '',
  color = 'currentColor',
  width = 20,
}) => {
  return (
    <svg className={className} height={width} viewBox="0 0 20 20" width={width}>
      <path
        clipRule="evenodd"
        d="M5 .833c.46 0 .833.373.833.834v1.666h3.333V1.667a.833.833 0 111.667 0v1.666h3.333V1.667a.833.833 0 011.667 0v1.666a3.333 3.333 0 013.333 3.334V15a3.333 3.333 0 01-3.333 3.333H4.166A3.333 3.333 0 01.833 15V6.667a3.333 3.333 0 013.333-3.334V1.667c0-.46.373-.834.834-.834zM4.166 5C3.246 5 2.5 5.746 2.5 6.667V15c0 .92.746 1.667 1.666 1.667h11.667c.92 0 1.667-.747 1.667-1.667V6.667c0-.92-.747-1.667-1.667-1.667H4.166z"
        fill={color}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M14.166 12.083c.69 0 1.25.56 1.25 1.25v.009a1.25 1.25 0 01-2.5 0v-.009c0-.69.56-1.25 1.25-1.25z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};
export { CalendarIconV2 };
