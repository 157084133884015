'use client';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useToggle } from '@bloom/ui/components/hooks/useToggle';
import { doNothing, emptyObject } from '@bloom/ui/utils/empty-value';

import { AsyncStatusEnum } from './useFetch';

type HTMLTagProps = React.ScriptHTMLAttributes<HTMLScriptElement>;
type Props = HTMLTagProps & Required<Pick<HTMLTagProps, 'src'>>;

interface IOptions extends Props {
  globalName: string;
  isScriptLoadingAllowed?: boolean;
  scope?: Partial<{ document: Document; window: Window }>;
}

function useAsyncScript(props: IOptions) {
  const { globalName, isScriptLoadingAllowed = true, scope, src, ...restScript } = props;

  if (
    (typeof scope?.document === 'undefined' && typeof document === 'undefined') ||
    (typeof scope?.window === 'undefined' && typeof window === 'undefined')
  ) {
    return useMemo(() => ({ isReady: false, loadScript: doNothing }), []);
  }

  const documentObj = scope?.document || document;
  const windowObj = scope?.window || window;

  const [isReady, { setTrue }] = useToggle(globalName in windowObj);

  const loadScript = useCallback(() => {
    return new Promise<boolean>((resolve) => {
      if (globalName && typeof windowObj[globalName] !== 'undefined') {
        setTrue();
        return;
      }

      if (documentObj.querySelector(`script[src="${src}"]`)) {
        const script = documentObj.querySelector(`script[src="${src}"]`) as HTMLScriptElement;
        if (script.dataset.complete === 'true') {
          setTrue();
          resolve(true);
          return;
        }
        script.onload = () => {
          setTrue();
          resolve(true);
        };
        return;
      }

      const script = documentObj.createElement('script');
      script.src = src;
      script.async = true;

      Object.entries(restScript).forEach(([key, value]) => {
        script[key] = value;
      });

      script.onload = () => {
        setTrue();
        resolve(true);
        script.dataset.complete = 'true';
      };

      script.onerror = () => {
        resolve(false);
      };

      documentObj.body.appendChild(script);
    });
  }, [documentObj, globalName, restScript, setTrue, src, windowObj]);

  useEffect(() => {
    if (src && !isReady && isScriptLoadingAllowed) {
      loadScript();
    }
  }, [
    documentObj,
    globalName,
    isReady,
    setTrue,
    src,
    windowObj,
    isScriptLoadingAllowed,
    restScript,
    loadScript,
  ]);

  return useMemo(() => ({ isReady, loadScript }) as const, [isReady, loadScript]);
}

// Cached script statuses
const cachedScriptStatuses = new Map<string, AsyncStatusEnum | undefined>();

function getScriptNode(src: string) {
  const node: HTMLScriptElement | null = document.querySelector(`script[src="${src}"]`);
  const status = node?.getAttribute('data-status') as AsyncStatusEnum | undefined;

  return { node, status };
}

function useScript(
  options: Props & { removeOnUnmount?: boolean; shouldPreventLoad?: boolean }
): AsyncStatusEnum {
  const {
    removeOnUnmount = false,
    shouldPreventLoad = false,
    ...restOptions
  } = options ?? emptyObject;

  const src = options.src ?? '';

  const [status, setStatus] = useState<AsyncStatusEnum>(() => {
    if (!src || shouldPreventLoad) {
      return AsyncStatusEnum.IDLE;
    }

    if (typeof window === 'undefined') {
      // SSR Handling - always return AsyncStatusEnum.PENDING
      return AsyncStatusEnum.PENDING;
    }

    return cachedScriptStatuses.get(src) ?? AsyncStatusEnum.PENDING;
  });

  useEffect(() => {
    if (!src || shouldPreventLoad) {
      return;
    }

    const cachedScriptStatus = cachedScriptStatuses.get(src);
    if (
      cachedScriptStatus === AsyncStatusEnum.SUCCESS ||
      cachedScriptStatus === AsyncStatusEnum.ERROR
    ) {
      // If the script is already cached, set its status immediately
      setStatus(cachedScriptStatus);
      return;
    }

    // Fetch existing script element by src
    // It may have been added by another instance of this hook
    const script = getScriptNode(src);
    let scriptNode = script.node;

    if (!scriptNode) {
      // Create script element and add it to document body
      scriptNode = document.createElement('script');
      scriptNode.async = true;

      Object.entries(restOptions).forEach(([key, value]: [string, string]) => {
        scriptNode[key] = value;
      });

      scriptNode.setAttribute('data-status', AsyncStatusEnum.PENDING);
      document.body.appendChild(scriptNode);

      // Store status in attribute on script
      // This can be read by other instances of this hook
      const setAttributeFromEvent = (event: Event) => {
        const scriptStatus: AsyncStatusEnum =
          event.type === 'load' ? AsyncStatusEnum.SUCCESS : AsyncStatusEnum.ERROR;

        scriptNode?.setAttribute('data-status', scriptStatus);
      };

      scriptNode.addEventListener('load', setAttributeFromEvent);
      scriptNode.addEventListener('error', setAttributeFromEvent);
    } else {
      // Grab existing script status from attribute and set to state.
      setStatus(script.status ?? cachedScriptStatus ?? AsyncStatusEnum.PENDING);
    }

    // Script event handler to update status in state
    // Note: Even if the script already exists we still need to add
    // event handlers to update the state for *this* hook instance.
    const setStateFromEvent = (event: Event) => {
      const newStatus: AsyncStatusEnum =
        event.type === 'load' ? AsyncStatusEnum.SUCCESS : AsyncStatusEnum.ERROR;
      setStatus(newStatus);
      cachedScriptStatuses.set(src, newStatus);
    };

    // Add event listeners
    scriptNode.addEventListener('load', setStateFromEvent);
    scriptNode.addEventListener('error', setStateFromEvent);

    // Remove event listeners on cleanup
    return () => {
      if (scriptNode) {
        scriptNode.removeEventListener('load', setStateFromEvent);
        scriptNode.removeEventListener('error', setStateFromEvent);
      }

      if (scriptNode && removeOnUnmount) {
        scriptNode.remove();
        cachedScriptStatuses.delete(src);
      }
    };
  }, [shouldPreventLoad, removeOnUnmount, options, src]);

  return status;
}

export { useAsyncScript, useScript };
