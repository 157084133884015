import React from 'react';

import { IIconProps } from './types';

const PencilIcon: React.FC<IIconProps> = ({ width = 20, className = '', color = '#111' }) => (
  <svg width={width} height={width} className={className} viewBox="0 0 20 20">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 17.601c0-.46.373-.833.833-.833h7.5a.833.833 0 010 1.666h-7.5A.833.833 0 019 17.601zM14.833 4.167a.934.934 0 00-.66.273L3.919 14.694l-.44 1.762 1.762-.44L15.494 5.761a.935.935 0 00-.66-1.595zm-1.839-.905a2.601 2.601 0 013.679 3.678L6.256 17.357a.833.833 0 01-.387.22l-3.334.833a.834.834 0 01-1.01-1.011l.833-3.333a.833.833 0 01.22-.388L12.993 3.262z"
      fill={color}
    />
  </svg>
);
export { PencilIcon };
