'use client';
import React, { useCallback, useDeferredValue, useEffect, useMemo, useRef, useState } from 'react';

import { Dropdown } from '@bloom/ui/components/Dropdown';
import { ChevronIcon } from '@bloom/ui/components/Icons/Chevron';
import { Input } from '@bloom/ui/components/Input';

import { PlusIcon } from '@bloom/library/components/Icon/Plus';

import { emptyObject } from '../utils/empty-value';
import { useToggle } from './hooks/useToggle';

const Combobox: React.FC<
  Omit<React.ComponentProps<typeof Dropdown>, 'Trigger'> & {
    // This label (+ add "something" menu item) appears when the user types in the input field.
    creatableItemLabel?: string;
    createErrorMessage?: string;
    // If you want to show the "add" label, set `defaultCreatableItemLabel`.
    // It will be replaced with 'creatableItemLabel' if the user types something in the input field.
    defaultCreatableItemLabel?: string;
    displayValue?: React.ReactNode;
    inputProps?: Partial<
      React.ComponentProps<typeof Input> & { onInputValueSet: (inputValue: string) => void }
    >;
    isCreatable?: boolean;
    loading?: boolean;
    placeholder?: string;
  }
> = (props) => {
  const {
    creatableItemLabel,
    createErrorMessage,
    defaultCreatableItemLabel,
    displayValue,
    inputProps,
    invalid,
    isCreatable = false,
    loading,
    menuItems,
    name = '',
    onChange,
    placeholder,
    ref,
    value,
    ...restDropdownProps
  } = props;

  const { placeholder: inputPropsPlaceholder, ...restInputProps } = inputProps || emptyObject;
  const computedPlaceholder = placeholder ?? inputPropsPlaceholder ?? '';

  const [open, { setState, setTrue, toggle }] = useToggle();

  const openRef = useRef(open);
  const loadingRef = useRef(loading);
  const valueRef = useRef(value);
  const clickingRef = useRef(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  // Show unfiltered list of items if the input has never been changed
  const [dirty, { setFalse: setDirtyFalse, setTrue: setDirtyTrue }] = useToggle();

  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const [inputValueState, setInputValueState] = useState(
    menuItems.find((o) => o.value === value)?.label || value || ''
  );

  const computedInputValue = (inputProps?.value as string) || inputValueState;
  const setComputedInputValue = inputProps?.onInputValueSet || setInputValueState;

  const deferredQuery = useDeferredValue(computedInputValue);

  const [onCreateError, setOnCreateError] = useState('');

  const handleChange = useCallback(
    (value: string) => {
      const selectedValue = menuItems.find((o) => o.value === value)?.label || value || '';

      if (typeof onChange === 'function') {
        onChange(name, value);
      }
      setComputedInputValue(selectedValue);
    },
    [menuItems, name, onChange, setComputedInputValue]
  );

  const createNewItem = useCallback(() => {
    setComputedInputValue('');
    handleChange(computedInputValue);
    setActiveIndex(null);
  }, [setComputedInputValue, handleChange, computedInputValue]);

  const items = useMemo(() => {
    return [
      ...(isCreatable && creatableItemLabel
        ? deferredQuery
          ? [
              {
                className: 'justify-start',
                'data-testid': 'custom-field-combobox-new-item',
                icon: <PlusIcon className="shrink-0" width={16} />,
                label: creatableItemLabel
                  .replace('{{deferredQuery}}', deferredQuery)
                  .replace('{{inputValue}}', deferredQuery),
                onClick: createNewItem,
              },
            ]
          : defaultCreatableItemLabel
            ? [
                {
                  className: 'justify-start',
                  icon: <PlusIcon className="shrink-0" width={16} />,
                  label: defaultCreatableItemLabel,
                  onClick: () => {
                    setOnCreateError(createErrorMessage || 'Please enter a value');
                  },
                },
              ]
            : []
        : []),
      ...menuItems.filter((item) => {
        const { filterable = true } = item;
        return dirty && filterable
          ? item.label.toLowerCase().includes(deferredQuery.toLowerCase())
          : true;
      }),
    ];
  }, [
    creatableItemLabel,
    createErrorMessage,
    createNewItem,
    defaultCreatableItemLabel,
    deferredQuery,
    dirty,
    isCreatable,
    menuItems,
  ]);

  useEffect(() => {
    if (value !== valueRef.current || (!open && openRef.current)) {
      const selectedOption = menuItems.find((o) => o.value === value);
      setComputedInputValue(selectedOption?.label || value || '');
    }
  }, [menuItems, open, setComputedInputValue, value]);

  useEffect(() => {
    if (inputRef.current !== inputRef.current?.ownerDocument.activeElement) {
      const selectedOption = menuItems.find((o) => o.value === value);
      setComputedInputValue(selectedOption?.label || value || '');
    }
  }, [menuItems, setComputedInputValue, value]);

  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  useEffect(() => {
    if (open) {
      const newIndex = items.findIndex((o) => o.value === value);
      if (newIndex !== selectedIndex) {
        setSelectedIndex(newIndex);
      }
    }
  }, [items, open, selectedIndex, value]);

  function onInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    if (!dirty) {
      setDirtyTrue();
    }

    setComputedInputValue(value);

    if (onCreateError) {
      setOnCreateError('');
    }

    if (activeIndex !== 0 && value) {
      setActiveIndex(0);
    }
  }

  useEffect(() => {
    openRef.current = open;
    return () => {
      if (open) {
        setDirtyFalse();
      }
    };
  }, [open, setDirtyFalse]);

  useEffect(() => {
    loadingRef.current = loading;
  }, [loading]);

  useEffect(() => {
    valueRef.current = value;
  }, [value]);

  return (
    <Dropdown
      Trigger={
        <Input
          Control={<ChevronIcon />}
          data-testid={props['data-testid']}
          errorMessage={onCreateError}
          invalid={invalid}
          loading={loading}
          onChange={onInputChange}
          onFocus={() => {
            // The menu toggles on click event and on focus event.
            // In the browser the events are fired in the following order:
            // mousedown -> focus (open menu )-> mouseup (toggle == close menu)
            // To prevent the flashing of the menu we should open the menu
            // of the focus event only if it's fired by Tab, or autofocus.
            if (!clickingRef.current) {
              // In React StrictMode autofocus does not work properly
              // The mode mounts the component twice, and the focus is lost.
              // This is a workaround to focus the input after the component is mounted.
              setTimeout(() => {
                setTrue();
              });
            }
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
              if (isCreatable && computedInputValue) {
                createNewItem();
              }
              // if (activeIndex != null && items[activeIndex]) {
              //   // setInputValue(items[activeIndex]?.label);
              //   // setActiveIndex(null);
              // } else {
              // }
            }
          }}
          onMouseDown={() => {
            clickingRef.current = true;
          }}
          onMouseUp={(e) => {
            if (e.currentTarget !== inputRef.current?.ownerDocument.activeElement) {
              toggle();
            }
            clickingRef.current = false;
          }}
          placeholder={
            displayValue ? (open && !value ? computedPlaceholder : undefined) : computedPlaceholder
          }
          ref={inputRef}
          value={computedInputValue}
          {...restInputProps}
        >
          {displayValue ? (open ? null : displayValue) : null}
        </Input>
      }
      hideWithNoItems={false}
      initialFocus={-1}
      menuItems={items}
      name={name}
      onChange={onChange}
      onOpenChange={setState}
      open={open}
      ref={ref}
      typeaheadEnabled={false}
      value={value}
      {...restDropdownProps}
    />
  );
};

export { Combobox };
