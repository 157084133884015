import React from 'react';

import { Input } from '@bloom/ui/components/Input';

const PhoneInput: React.FC<React.ComponentProps<typeof Input>> = (props) => {
  const { onChange, ...restProps } = props;

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    const invalidChars = /[^0-9 +()-]/;
    // Update value if it contains only valid chars.
    if (typeof onChange === 'function' && !invalidChars.test(value)) {
      onChange(e);
    } else {
      e.preventDefault();
    }
  }

  return <Input {...restProps} onChange={handleChange} type="tel" />;
};

export { PhoneInput };
