import { useCallback, useMemo } from 'react';

import { LocaleSettingResponse } from '@bloom/codegen/models/LocaleSettingResponse';

import { useAccount } from '@bloom/library/components/hooks/useAccounts';

export const defaultLocaleSettings = {
  dateFormat: 'MM/DD/YYYY',
  hourFormat: '12',
  temperatureUnit: 'F',
  timeFormat: 'hh:mma',
};

export function useLocaleSettings() {
  const { account, updateAccount } = useAccount();

  const localeSettings: LocaleSettingResponse =
    account?.settings?.localeSettings || defaultLocaleSettings;

  const updateLocaleSettings = useCallback(
    (request: LocaleSettingResponse) => {
      updateAccount({ settings: { localeSettings: request } });
    },
    [updateAccount]
  );

  return useMemo(
    () => ({ localeSettings, updateLocaleSettings }),
    [localeSettings, updateLocaleSettings]
  );
}
