import { ContractResponse } from '@bloom/codegen/models/ContractResponse';

export enum ContractTypeEnum {
  PDF = 'PDF',
  FORM = 'FORM',
  // TODO: Add more types
}

interface IContractTemplateBase {
  createdAt: string;
  customFields: Array<Record<'title' | 'value', string>> | null;
  displayName: string;
  id: string;
}

interface IContractTemplateForm extends IContractTemplateBase {
  body: string;
  type: ContractTypeEnum.FORM;
}

interface IContractTemplatePdf extends IContractTemplateBase {
  mimeType: string;
  size: number;
  type: ContractTypeEnum.PDF;
  url: string;
}

export type IContractTemplate = IContractTemplateForm | IContractTemplatePdf;

type IContractBase = Pick<
  ContractResponse,
  | 'account'
  | 'customFields'
  | 'date'
  | 'id'
  | 'invoiceNumber'
  | 'isAutoCountersignEnabled'
  | 'ownerId'
  | 'redirectUrl'
  | 'seller'
  | 'sellerSignature'
  | 'signatures'
  | 'signees'
  | 'title'
>;

export interface IContractForm extends IContractBase {
  body: string;
  type: ContractTypeEnum.FORM;
}

export interface IContractPDF extends IContractBase {
  mimeType: string;
  size: number;
  type: ContractTypeEnum.PDF;
  url: string;
}

export type IContract = IContractForm | IContractPDF;
