import React from 'react';

import { IIconProps } from './types';

const PictureIconV2: React.FC<IIconProps> = ({
  width = 36,
  className = '',
  color = 'currentColor',
}) => (
  <svg className={className} height={width} viewBox="0 0 36 36" width={width}>
    <path
      clipRule="evenodd"
      d="M12 9a3 3 0 100 6 3 3 0 000-6zm-1.5 3a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z"
      fill={color}
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M3.75 7.5A3.75 3.75 0 017.5 3.75h21a3.75 3.75 0 013.75 3.75v21a3.75 3.75 0 01-3.75 3.75h-21a3.75 3.75 0 01-3.75-3.75v-21zM7.5 5.25A2.25 2.25 0 005.25 7.5v21a2.25 2.25 0 002.25 2.25h.44l14.624-14.625a1.5 1.5 0 012.121 0l6.065 6.064V7.5a2.25 2.25 0 00-2.25-2.25h-21zm21 25.5H10.06l13.565-13.564 7.125 7.125V28.5a2.25 2.25 0 01-2.25 2.25z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);
export { PictureIconV2 };
