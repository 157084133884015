import React from 'react';

import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { twMerge } from 'tailwind-merge';

import { useToggle } from '@bloom/ui/components/hooks/useToggle';
import { Input } from '@bloom/ui/components/Input';
import { MenuItem } from '@bloom/ui/components/MenuItem';
import { Popover, PopoverContent, PopoverTrigger } from '@bloom/ui/components/Popover';

import { useAsyncScript } from '@bloom/library/components/hooks/useAsyncScript';
import { IAddressNew } from '@bloom/library/types/general';

interface IProps extends Omit<Partial<React.ComponentProps<typeof Input>>, 'onChange'> {
  locationName?: string;
  onChange: (value: string) => void;
  onSelect: (address: IAddressNew, rawValue: google.maps.GeocoderResult) => void;
  root?: HTMLElement;
}

const AddressAutocomplete: React.FC<IProps> = (props) => {
  const {
    className,
    inputClassName,
    locationName,
    onChange,
    onBlur,
    onSelect,
    placeholder,
    root,
    value,
    ...restInputProps
  } = props;

  const [isFocused, { setFalse, setTrue }] = useToggle();

  const { isReady } = useAsyncScript({
    globalName: 'google',
    src: `https://maps.googleapis.com/maps/api/js?libraries=places&key=${process.env.GOOGLE_API_KEY}`,
  });

  function handleAddressSelect(value) {
    geocodeByAddress(value).then((rawValue) => {
      const [{ address_components: ac = [] }] = rawValue;
      const streetNumber =
        (ac.find((c) => c.types.indexOf('street_number') > -1) || {}).long_name || '';
      const route = (ac.find((c) => c.types.indexOf('route') > -1) || {}).short_name || '';
      const city = (ac.find((c) => c.types.indexOf('locality') > -1) || {}).long_name || '';
      const state =
        (ac.find((c) => c.types.indexOf('administrative_area_level_1') > -1) || {}).short_name ||
        '';
      const country = (ac.find((c) => c.types.indexOf('country') > -1) || {}).short_name || '';
      const postalCode =
        (ac.find((c) => c.types.indexOf('postal_code') > -1) || {}).long_name || '';

      const addressObj: IAddressNew = {
        city,
        country,
        name: locationName,
        postalCode,
        province: country === 'US' ? '' : state,
        state: country === 'US' ? state : '',
        street: `${streetNumber} ${route}`,
      };

      onSelect(addressObj, rawValue[0]);

      // Hide the suggestions list on selecting an address.
      setFalse();
    });
  }

  function handleKeyDown(e) {
    // Prevent accidentally submitting the form
    // by selecting an address.
    if (e.key === 'Enter') {
      e.stopPropagation();
    }

    // There can be a race condition with autofocus: true
    // the focus event triggers, then the component re-mounts
    // and the isFocused state reset to false.
    // which prevents the popover from opening.
    if (document.activeElement === e.target && !isFocused) {
      setTrue();
    }
  }

  // AddressAutocomplete is an async component dependent on 3rd party remote script.
  // replace it with normal input in text env
  if (process.env.NODE_ENV === 'test') {
    return (
      <Input
        data-testid={props['data-testid'] || ''}
        inputClassName={inputClassName}
        label={placeholder}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        value={value}
        {...restInputProps}
      />
    );
  }

  if (isReady) {
    return (
      <PlacesAutocomplete
        onChange={onChange}
        onSelect={handleAddressSelect}
        searchOptions={{ types: ['address'] }}
        value={value}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
          <div className={twMerge('relative', className)} onKeyDown={handleKeyDown}>
            <Popover
              matchParentWidth
              open={isFocused && suggestions.length > 0}
              placement="bottom"
              root={root}
            >
              <PopoverTrigger asChild>
                <Input
                  {...getInputProps({
                    'data-testid': props['data-testid'],
                    inputClassName,
                    label: placeholder,
                    onBlur: (e) => {
                      if (typeof onBlur === 'function') {
                        onBlur(e);
                      }
                      setFalse();
                    },
                    placeholder,
                  })}
                  {...restInputProps}
                  // autoComplete="off" doesn't turn off autocompletion
                  // we need to omit native autocompletion to avoid overlapping with our suggestions list
                  autoComplete="new-password"
                  onFocus={setTrue}
                />
              </PopoverTrigger>
              <PopoverContent>
                {React.Children.toArray(
                  suggestions.map((suggestion, index) => (
                    <MenuItem
                      {...getSuggestionItemProps(suggestion)}
                      data-testid={`${props['data-testid']}-menu-item-${index}`}
                      onMouseDown={handleAddressSelect.bind(null, suggestion.description)}
                    >
                      <span className="flex-1 truncate">{suggestion.description}</span>
                    </MenuItem>
                  ))
                )}
              </PopoverContent>
            </Popover>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
  return null;
};

export { AddressAutocomplete };
