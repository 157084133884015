import React from 'react';

import { twMerge } from 'tailwind-merge';

import { Text } from '@bloom/ui/components/Typography/Text';

type IPropsBase = React.ComponentProps<typeof Text>;

interface IPropsHtmlAnchor extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  asChild?: false;
  href: string;
}

interface IPropsComponent extends IPropsBase {
  asChild: true;
  children: React.ReactNode;
}

type IProps = IPropsHtmlAnchor | IPropsComponent;

const AnchorText = React.forwardRef<HTMLElement, React.PropsWithChildren<IProps>>((props, ref) => {
  const { asChild, children, className = '', ...restProps } = props;

  return asChild && React.isValidElement(children) ? (
    React.cloneElement(children, {
      ...restProps,
      ...children.props,
      className: twMerge(
        'cursor-pointer underline hover:no-underline',
        children.props.className,
        className
      ),
      ref,
    })
  ) : (
    <Text
      as="a"
      className={twMerge('cursor-pointer underline hover:no-underline', className)}
      ref={ref}
      {...restProps}
    >
      {children}
    </Text>
  );
});

export { AnchorText };
